<template>
  <v-container fluid class="pa-1">
    <v-tabs-items v-model="global.check_tab_mode">
      <v-tab-item>
        <div class="d-flex flex-wrap justify-space-around">
          <v-card
            elevation="0"
            :width="picwidth"
            :height="picwidth * 1.5"
            v-for="item in people"
            :key="item.randkey"
          >
            <v-card-text class="pa-0">
              <v-img
                v-on:dblclick="favorite(item)"
                :src="`${apiurl}/images/${item.randkey}.jpg`"
              >
                <!--             :max-width="picwidth" -->
                <v-btn
                  v-if="item.gotonext == 1"
                  class="mt-8 mr-n4"
                  color="yellow"
                  dark
                  elevation="0"
                  fab
                  top
                  right
                  absolute
                  large
                >
                  <v-icon x-large>mdi-star</v-icon>
                </v-btn>
              </v-img>
            </v-card-text>
          </v-card>
        </div>
        <p
          v-if="!this.$store.state.global.loading"
          class="mt-15 text-center copyright2"
        >
          สิ้นสุดหน้าที่ {{ activePage }}
        </p>
        <v-footer app padless inset>
          <v-container class="max-width pa-0">
            <v-pagination
              v-model="activePage"
              :length="total_page"
            ></v-pagination>
          </v-container>
        </v-footer>
      </v-tab-item>
      <v-tab-item>
        <div class="d-flex flex-wrap justify-space-around">
          <v-card
            elevation="0"
            :width="picwidth"
            :height="picwidth * 1.5"
            v-for="item in people_star"
            :key="item.randkey"
          >
            <v-card-text class="pa-0">
              <v-img
                v-on:dblclick="favorite_star(item)"
                :src="`${apiurl}/images/${item.randkey}.jpg`"
              >
                <!--             :max-width="picwidth" -->
                <v-btn
                  v-if="item.gotonext == 1"
                  class="mt-8 mr-n4"
                  color="yellow"
                  dark
                  elevation="0"
                  fab
                  top
                  right
                  absolute
                  large
                >
                  <v-icon x-large>mdi-star</v-icon>
                </v-btn>
              </v-img>
            </v-card-text>
          </v-card>
        </div>
        <v-footer
          v-if="this.$store.state.user_info.frm_check == '1'"
          app
          padless
          inset
        >
          <v-container class="max-width pa-0 d-flex justify-center">
            <v-checkbox
              color="teal"
              class="pa-2 ma-0"
              dense
              hide-details
              v-model="conf"
              :label="`ยืนยันผลการตรวจหลักสูตร ${$route.params.room} (ผ่าน ${people_star.length} ท่าน)`"
              @change="update_conf($route.params.room)"
            ></v-checkbox>
          </v-container>
        </v-footer>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5"> กรุณายืนยัน </v-card-title>
        <v-card-text class="text-subtitle-1"
          >ต้องการนำ น.ศ. ออกจากรายการหรือไม่</v-card-text
        >
        <v-card-actions>
          <v-btn color="red darken-1" text @click="dialog = false">
            ยกเลิก
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="do_star_remove()">
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { mapState } from "vuex";
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
};
export default {
  data: () => ({
    dialog: false,
    activeTab: 0,
    activePage: 1,
    total_page: 0,
    people: [],
    people_star: [],
    picwidth: 0,
    index_people_star: -1,
    index_people: -1,
    conf: false,
  }),
  methods: {
    async fetch_people(room, page = 1) {
      this.$store.state.global.loading = await true;
      this.people = await [];
      let { data } = await axios.get(
        `${this.apiurl}/api/people/check/${room}?page=${page}`,
        {
          params: {
            room: this.room,
          },
        }
      );
      this.people = await data.data;
      this.activePage = data.current_page;
      this.total_page = data.last_page;
      this.$store.state.global.loading = await false;
      // await console.log(data);
    },
    async favorite(item) {
      if (this.$store.state.user_info.frm_check == "1") {
        //===============
        let index_people = await this.people.findIndex(
          (x) => x.randkey == item.randkey
        );
        this.people[index_people].gotonext =
          (await this.people[index_people].gotonext) == 0 ? 1 : 0;
        //===============
        await this.do_star_SQL(item, this.people[index_people].gotonext);
        await this.do_star_array(this.people[index_people]);

        //===============
      }
    },
    async favorite_star(item) {
      if (this.$store.state.user_info.frm_check == "1") {
        //===============
        let index_people_star = await this.people_star.findIndex(
          (x) => x.randkey == item.randkey
        );

        let index_people = await this.people.findIndex(
          (x) => x.randkey == item.randkey
        );

        this.confirm_remove_star(index_people_star, index_people);
      }
    },

    confirm_remove_star(index_people_star, index_people) {
      this.dialog = true;
      this.index_people_star = index_people_star;
      this.index_people = index_people;
    },

    async do_star_remove() {
      this.dialog = await false;
      let item = await this.people_star[this.index_people_star];
      await this.people_star.splice(this.index_people_star, 1);
      await this.do_star_SQL(item, 0);
      if (this.index_people != -1) {
        this.people[this.index_people].gotonext = await 0;
      }
      this.index_people_star = await -1;
      this.index_people_ = await -1;
    },

    async do_star_SQL(item, gotonext) {
      let formData = await new FormData();
      await formData.append("id", item.id);
      await formData.append("randkey", item.randkey);
      await formData.append("gotonext", gotonext);
      await axios.post(
        this.apiurl + "/api/people/" + item.id + "?_method=PUT",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // .then((response) => {
      //   console.log(response.data.gotonext);
      // })
      // .catch((error) => {
      //   console.log(error.response.data.errors);
      // });
    },
    moveTo() {
      // let to = this.moveToDown ? this.$refs.description.offsetTop - 60 : 0;

      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      this.moveToDown = !this.moveToDown;
    },
    async widthcal() {
      let perpage = 1;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          perpage = 2;
          break;
        case "sm":
          perpage = 3;
          break;
        case "md":
          perpage = 4;
          break;
        case "lg":
          perpage = 6;
          break;
        case "xl":
          perpage = 8;
          break;
      }
      this.picwidth = (await window.innerWidth) / perpage - 5;
      await this.fetch_people(this.$route.params.room);
      this.load_people_star_tab(this.$route.params.room);
    },
    async load_people_star_tab(room) {
      let { data } = await axios.get(
        `${this.apiurl}/api/people/checkstar/${room}`,
        {
          params: {
            room: this.room,
          },
        }
      );
      this.people_star = await data;
    },

    do_star_array(people) {
      if (people.gotonext == 1) {
        this.people_star.push(people);
      } else {
        let index_people_star = this.people_star.findIndex(
          (x) => x.randkey == people.randkey
        );
        if (index_people_star != -1) {
          this.people_star.splice(index_people_star, 1);
        }
      }
    },
    async fetch_conf(room) {
      let { data } = await axios.get(`${this.apiurl}/api/roomconfirm/${room}`, {
        params: {},
      });
      this.conf = (await data) == "1" ? true : false;
    },
    async update_conf(room) {
      let formData = await new FormData();
      await formData.append("conf", this.conf);
      await formData.append("room", room);
      await axios
        .post(`${this.apiurl}/api/roomconfirm/${room}?_method=PUT`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {})
        .catch(() => {});
    },
  },
  computed: {
    apiurl() {
      return this.$store.state.apiurl;
    },
    tab_mode() {
      return this.$store.state.global.check_tab_mode;
    },
    global: {
      get() {
        return this.$store.getters.get_global;
      },
      set(value) {
        this.$store.dispatch("handle_global", {
          global: value,
        });
      },
    },
  },
  mounted() {
    this.widthcal();
    this.fetch_conf(this.$route.params.room);
    // this.global.check_tab_mode = 0;
  },
  watch: {
    activePage: {
      handler() {
        this.fetch_people(this.$route.params.room, this.activePage);
        // window.scrollTo(0, 0);
        // this.moveTo();
      },
      deep: true,
    },
    "$route.params.room": {
      handler() {
        // this.activePage = 1;
        this.fetch_people(this.$route.params.room, 1);
        this.load_people_star_tab(this.$route.params.room);
        this.fetch_conf(this.$route.params.room);
        // window.scrollTo(0, 0);
        // this.moveTo();
      },
      deep: true,
    },
  },
};
</script>

<style>
.copyright2 {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  height: 40px;
  font-size: 1.2rem;
}
</style>